/* critical:start */
.video-player {
    position: relative;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000;
    height: 100%;
    width: 100%;
}

.video-wrapper {
    --video-controls-height: 52px;
    height: 100%;
    width: 100%;
    position: absolute;
    background: #000;
    top: 0;
    left: 0;
    overflow: hidden;
    cursor: pointer;
}

.video-wrapper .video-inner {
    height: 100%;
    width: 100%;
    position: relative;
}
/* critical:end */

.video-wrapper.docked-default {
    position: fixed;
    top: unset;
    right: 91px;
    bottom: 0;
    left: unset;
    z-index: 100;
    width: 539px;
    height: calc(539px * 0.5625);
}

.video-wrapper.docked-default.vertical {
    height: 550px;
    width: calc(550px * 0.5625);
}

.video-wrapper.docked-wsj-article {
    position: fixed;
    top: unset;
    right: 10px;
    bottom: 0;
    left: unset;
    height: 310px;
    width: 380px;
    z-index: var(--z-slimline-header, 60);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
    display: flex;
    flex-direction: column;
}

.video-wrapper.docked-wsj-article .video-inner {
    aspect-ratio: 16 / 9;
    height: auto;
}

.video-wsj-article-docked-controls {
    z-index: var(--z-slimline-header, 60);
    top: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 16px 16px 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
    cursor: unset;
    flex: 1;
}

@media (min-width: 640px) and (max-width: 979px) {
    .video-wrapper.docked-default {
        right: 45px;
        width: 40vw;
        height: calc(40vw * 0.5625);
    }
}

@media screen and (max-width: 639px) {
    .video-wrapper.docked-default {
        right: 0;
        /* TODO: Hardcoded to be underneath the slimline header for now. We may need a way for the page to tell the player where to position the player */
        top: var(--slimlineheader-height, 54px);
        width: 100vw;
        height: calc(100vw * 0.5625);
    }

    .video-wrapper.docked-default.vertical {
        aspect-ratio: 9 / 16;
        height: auto;
        width: 100vw;
    }

    .video-wrapper.docked-wsj-article {
        right: 0;
        left: 0;
        top: 0 !important;
        bottom: unset;
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .video-wrapper.docked-wsj-article .video-inner {
        max-width: 40%;
    }

    .video-wsj-article-docked-controls {
        padding: 8px 11px 0px 12px;
    }
}

.video-wrapper button {
    border: none;
    background: none;
    cursor: pointer;
}

/* svgs prevent click events from bubbling up. This makes A/B testing with Optimizely difficult  */
.video-wrapper button svg {
    pointer-events: none;
}

.video-wrapper button:focus,
.video-wrapper a:focus,
.video-wrapper [tabindex='0']:focus,
.video-wrapper [tabindex='1']:focus {
    outline: none;
}

.video-player.show-focus button:focus,
.video-player.show-focus a:focus,
.video-player.show-focus [tabindex='0']:focus,
.video-player.show-focus [tabindex='1']:focus {
    outline: #0274b6 solid 3px;
    outline-offset: 1px;
}

.bigTop__videoPlayerBox .bigTop__videoPlayer {
    padding-bottom: 0 !important;
}

.bigTop__videoPlayerBox .bigTop__videoPlayer {
    padding-bottom: 0 !important;
}

.video-adLearn {
    position: absolute;
    z-index: 30;
    color: #fff;
    font-family: sans-serif;
    font-size: 25px;
    right: 10px;
    top: 10px;
}

.nocursor {
    cursor: none;
}

.video-wrapper:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

.wsjVideoPlayer > .video-wrapper:-webkit-full-screen {
    position: relative !important;
}

.video-wrapper .video-time {
    color: #fff;
    opacity: 0.8;
    font-family: 'Retina Narrow', 'Retina', Helvetica, sans-serif;
    font-weight: 400;
    height: auto;
    padding-top: 10px;
    user-select: none;
    font-size: 18px;
    pointer-events: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-wrapper .video-controls-container {
    pointer-events: none;
    position: absolute;
    z-index: 5;
    bottom: 0;
    width: 100%;
    height: 130px;
    user-select: none;
    display: block;
    -webkit-user-select: none;
}

.video-wrapper .video-controls-background {
    height: 100%;
    width: 100%;
    background: linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.11) 19%,
        rgba(0, 0, 0, 0.33) 50%,
        rgba(0, 0, 0, 0.9) 100%
    );
    opacity: 0;
    transition-delay: 1s;
    -webkit-transition-delay: 1s;
    -moz-transition-dealy: 1s;
    -o-transition-delay: 1s;
    -ms-transition-delay: 1s;
}

.video-wrapper .video-fade-in,
.video-wrapper .video-fade-in > * {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.video-wrapper .video-fade-out,
.video-wrapper .video-fade-out > * {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.video-wrapper .video-fade-out .video-controls > *,
.video-wrapper .video-fade-out .video-controls {
    pointer-events: none !important;
}

.video-wrapper .video-controls {
    display: flex;
    pointer-events: all;
    cursor: default;
    width: 100%;
    padding: 0 11px 0 20px;
    height: var(--video-controls-height);
    bottom: 3px;
    line-height: 46px;
    text-align: left;
    direction: ltr;
    position: absolute;
    z-index: 5;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box;
    transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
}

.video-wrapper .video-controls-wrapper {
    width: 100%;
    height: 100%;
    position: static;
}
.video-wrapper .video-adContainer {
    height: 100%;
    width: 100%;
    z-index: 4;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    visibility: hidden;
}

.video-wrapper .video-adContainer svg {
    visibility: inherit !important;
}

.video-wrapper .ad-loading-screen {
    background: #000;
    height: 100%;
    width: 100%;
    color: #fff;
    position: absolute;
    z-index: 3;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.video-wrapper .ad-loading-screen span {
    font-size: 30px;
    font-family: 'Retina', Helvetica, sans-serif;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    position: relative;
    margin-top: 30px;
}
.video-wrapper .ad-loading-screen .video-loading {
    position: relative;
    top: auto;
}

.player-16U .ad-loading-screen span {
    font-size: 28px;
}
.player-12U .ad-loading-screen span {
    font-size: 19px;
}
.player-8U .ad-loading-screen span {
    font-size: 15px;
}

.player-4U .ad-loading-screen span,
.player-tiny .ad-loading-screen span {
    font-size: 10px;
}

.video-wrapper .disableMouse {
    pointer-events: none;
}

.video-wrapper .video-controls .filler {
    flex-grow: 1;
}

.video-wrapper .video-controls .f-r {
    margin-left: auto;
    margin-right: 15px;
}

.video-wrapper .video-controls .f-r div {
    height: 100%;
}

.video-wrapper .video-advertisment .video-time {
    display: none !important;
}

.video-wrapper .video-controls::before {
    content: '';
    display: inline-block;
    height: 100%;
    width: 12px;
}

.video-wrapper .gradient-bottom {
    bottom: 0;
    box-shadow: inset 0 -120px 90px -90px rgba(8, 8, 8, 0.8);
    height: 120px;
    width: 100%;
    position: absolute;
    -moz-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    pointer-events: none;
}

to {
    opacity: 1;
    transform: scale(1);
}

.video-player .videoMsg {
    font-family: 'Retina', Helvetica, sans-serif;
    text-align: center;
    color: #fff;
    line-height: 150%;
    position: absolute;
    font-size: 3vw;
    text-shadow: 2px 2px 0px #000000;
    width: 50%;
    right: 2%;
    top: 2%;
}

.video-player .videoMsg img {
    border: none;
    height: auto;
    width: auto;
    margin: 0 auto;
}

.video-player .videoErrorMsg {
    font-family: 'Retina', Helvetica, sans-serif;
    color: #fff;
    font-size: 25px;
    display: flex;
    align-items: center;
    height: 100%;
}

.video-player .videoErrorMsg > div {
    text-align: center;
    width: 100%;
}

.video-player .player-12U .videoErrorMsg,
.video-player .player-16U .videoErrorMsg {
    font-size: 25px;
}

.video-player .player-8U .videoErrorMsg,
.video-player .player-4U .videoErrorMsg,
.video-player .player-tiny .videoErrorMsg {
    font-size: 18px;
}

.video-controls > .video-volume {
    opacity: 0.8;
    margin-right: 8px;
}

.mobile-player .video-controls > .video-volume:hover ~ .video-time {
    opacity: 0.8 !important;
}

.video-controls > .video-volume > .video-volume-slider {
    height: 42px;
    width: 80px;
    opacity: 0;
    transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    position: absolute;
    left: 100px;
    margin-left: 0;
    top: 11px;
    pointer-events: none;
}

.video-volume-slider > div {
    position: absolute;
    top: 18px;
}

.video-volume-slider > .video-volume-b {
    width: 80px;
    height: 2.1px;
    background-color: rgba(255, 255, 255, 0.2);
}

.video-volume-slider > .video-volume-v {
    width: 50px;
    height: 3px;
    background-color: white;
}

.video-volume-slider > .video-volume-h {
    border-radius: 50%;
    height: 13px;
    width: 13px;
    background-color: white;
    top: 13.4px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.video-wrapper .video-controls .video-volume-g-videoplayer {
    height: 100%;
    width: 32px;
    display: block;
    margin: 0;
}

.video-volume-g-videoplayer svg {
    height: 81px;
}

.video-wrapper .video-advertisment {
    bottom: 0px;
}

.video-wrapper:hover > .video-controls {
    bottom: 0;
}

.video-wrapper:hover > .static {
    bottom: 0px !important;
}

.video-wrapper > .control_static {
    bottom: 0px !important;
}

.video-wrapper .video-sound {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;
    top: 0;
    font-family: 'RetinaMedium', Helvetica, sans-serif;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.video-wrapper .video-sound svg {
    fill: #000;
    position: absolute;
    width: 25px;
}

.video-wrapper .video-sound div {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 25px;
    border-radius: 3px;
    overflow: hidden;
}
.video-wrapper .video-sound span {
    display: inline-block;
    height: 100%;
    line-height: 27px;
}

.video-wrapper .video-sound div span.live {
    background: #ba0000;
    color: #fff;
    padding: 0 10px;
}

.video-wrapper .video-sound div span.cta {
    background: #fff;
}

.video-wrapper .video-sound div span.cta span {
    padding: 0px 10px 0 30px;
}

.video-wrapper .video-cc-inactive svg {
    fill: #7b7b7b;
}

.video-wrapper .video-cc-inactive:hover svg {
    fill: #ccc;
}

.video-wrapper > video,
.video-wrapper .video-play-overlay,
.video-wrapper > video,
.video-wrapper .video-play-overlay {
    display: none;
}

.video-wrapper svg {
    fill: #fff;
    visibility: visible !important;
    height: 100%;
}

.video-controls > .video-volume:hover,
.video-volume {
    width: 120px;
}

.mobile-player .video-controls > .video-volume:hover,
.video-volume {
    width: auto;
}

.disabled-btn {
    pointer-events: none;
}

.video-wrapper .video-controls Button,
.video-wrapper div.video-volume,
.video-wrapper .video-controls Button {
    pointer-events: all;
    cursor: pointer;
    height: 32px;
    display: inline-block;
    /* overflow: hidden; */
    border: none;
    background-color: transparent;
    color: inherit;
    text-align: inherit;
    font-size: 100%;
    font-family: inherit;
    cursor: pointer;
    line-height: inherit;
    opacity: 0.8;
    -webkit-font-smoothing: antialiased;
    transform-origin: 50% 50%;
    padding: 0;
    margin-top: 15px;
    margin-right: 15px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

.video-wrapper div.video-volume {
    margin-right: 5px;
}

.video-wrapper .video-controls Button svg {
    height: 100%;
}

.video-controls Button,
.video-wrapper div.video-volume:hover,
.video-controls Button,
.video-wrapper div.video-volume:focus,
.video-wrapper .video-controls Button:hover,
.video-wrapper .video-controls Button:focus {
    opacity: 1;
}

.video-controls Button,
.video-wrapper .video-controls Button:active {
    opacity: 0.8;
}

.video-controls > .video-volume:hover .video-volume-slider,
.video-controls > .video-volume .video-volume-slider:hover,
.video-controls > .video-volume > button:focus ~ .video-volume-slider,
.video-controls > .video-volume .video-volume-slider:focus {
    opacity: 1;
    pointer-events: all;
}

.video-controls > .video-volume:hover ~ .video-time,
.video-controls > .video-volume .video-time {
    opacity: 0;
}

/* Hide right side controls on vv to show the volume bar and not cause layout issues.  Only on non mobile and vv. */
.video-player:not(.mobile-player) .player-tiny.vertical .video-controls > .video-volume:hover ~ .video-time,
.video-player:not(.mobile-player) .player-tiny.vertical .video-controls > .video-volume:hover ~ .video-cc,
.video-player:not(.mobile-player) .player-tiny.vertical .video-controls > .video-volume:hover ~ .video-fullscreen {
    display: none !important;
}

.mobile-player .video-controls > .video-volume .video-volume-slider,
.mobile-player .video-controls > .video-volume:hover .video-volume-slider {
    opacity: 0;
    display: none;
}

.video-wrapper .vr-alert {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-wrapper .vr-alert p {
    color: #fff;
    font-size: 2vw;
    font-family: 'Retina', sans-serif;
}

/* Marketwatch Hack */
.over-video {
    z-index: 6;
}
::cue {
    font-family: 'Retina', sans-serif;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .video-wrapper .video-adContainer {
        pointer-events: none;
    }
}

.is-paused {
    animation-play-state: paused;
}
@media only screen and (max-width: 400px) {
    .video-thumb-content .video-duration,
    .video-thumb-content .video-caption {
        display: none;
    }
}
/* Future of Everything */

.vidThumb .video-thumb-content-foe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
}

.vidThumb .video-thumb-content-foe IMG {
    position: absolute;
}

.vidThumb .video-thumb-content-foe .video-title {
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    max-width: 100%;
    width: 90%;
    margin: 0 auto;
    text-shadow: none;
}

.vidThumb .video-thumb-content-foe .video-thumb-text {
    order: 2;
    position: relative;
}

.vidThumb .video-thumb-content-foe .video-thumb-foe-series-name {
    order: 1;
    z-index: 1;
    font-size: 15px;
    letter-spacing: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.vidThumb .video-thumb-content-foe .video-hint {
    order: 3;
    position: relative;
    z-index: 10;
    height: 50px;
    width: 100%;
    top: auto;
    transform: none;
    margin-top: 20px;
}

.vidThumb .video-thumb-content-foe .video-hint svg {
    height: 35px !important;
    width: 35px !important;
}
/* 4U */
.player-4U .video-thumb-content-foe .video-title,
.player-tiny .video-thumb-content-foe .video-title {
    font-size: 32px;
    line-height: 39px;
}
/* 8U */

/* 12U */
.player-12U .vidThumb .video-thumb-content-foe .video-hint svg {
    height: 45px !important;
    width: 45px !important;
}
.player-12U .vidThumb .video-thumb-content-foe .video-title {
    font-size: 50px;
    line-height: 58px;
}
/* 16U */
.player-16U .vidThumb .video-thumb-content-foe .video-hint svg {
    height: 60px !important;
    width: 60px !important;
}
.player-16U .vidThumb .video-thumb-content-foe .video-title {
    font-size: 50px;
    line-height: 58px;
}

.progressbar-text {
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
}

.progressbar-text {
    width: 83.83px;
    height: 24px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: 'Retina Narrow';
    text-align: center;
    letter-spacing: 0px;
    line-height: 27px;
}

.ad-top-background {
    position: absolute;
    pointer-events: none;
}

.ad-top-background {
    background: linear-gradient(
        to top,
        rgba(34, 34, 34, 0) 0%,
        rgba(34, 34, 34, 0.11) 38%,
        rgba(34, 34, 34, 0.33) 100%
    );
    border-radius: 0px 0px 0px 0px;
    height: 132px;
    width: 100%;
}

.visually-hidden {
    left: 65px;
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    /* added line */
    pointer-events: none;
}

.CircularProgressbar {
    width: 100%;
    vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: rgba(255, 255, 255, 1);
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: rgba(255, 255, 255, 0.5);
    stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
    color: #fff;
    font-size: 20px;
    font-family: 'Retina Narrow';
    text-align: center;
    letter-spacing: 0;
    line-height: 27px;
    text-shadow: 0 1px 6px rgb(0 0 0 / 50%);
}

.CircularProgressbar .CircularProgressbar-background {
    fill: rgba(255, 255, 255, 0.5);
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
    fill: rgba(255, 255, 255, 0.5);
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
}
