.clickForSoundWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 5;
    top: 20px;
    left: 20px;
    height: 25px;
    padding: 5px 5px 5px 0;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
    background: #fff !important;
    font-family: 'RetinaMedium', Helvetica, sans-serif;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    transition: 0.2s;
    color: #000;
}

.clickForSoundWrapper svg {
    fill: #000;
    height: 24px;
    width: 24px;
    margin-right: 5px;
}

.clickForSoundWrapperWithLive {
    transform: translateX(47px);
}

.clickForSoundWrapperWithAdvertisment {
    transform: translateY(65px);
}

@media (hover: none) {
    .clickForSoundWrapper:hover,
    .clickForSoundWrapper:hover svg {
        color: #0080c3;
        fill: #0080c3;
    }
}
