.videoTitleContainer {
    position: absolute;
    width: 100%;
    height: 30%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.34) 39%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 1;
    pointer-events: none;
}

.videoTitle {
    position: relative;
    margin: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 135%;
    width: calc(100% -40px);
    color: rgb(255, 255, 255);
    font-family: 'Escrow Condensed', Georgia, serif;
}

:global(.player-4U) .videoTitle {
    font-size: 22px;
}

:global(.player-8U) .videoTitle {
    font-size: 32px;
    line-height: 34px;
}

:global(.player-12U) .videoTitle,
:global(.player-16U) .videoTitle {
    font-size: 40px;
}
